
.hero {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-size: cover;
    background-position: center;
    max-height: 500px;
    height: 100vh;
    border-bottom: 1.5  px solid #000 ;

    @media (max-width: 768px) {
        height: 100%;
    }
}