.slider-container{
  position: relative;
  width: 100%;
  height: 100%;

  .slide-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 1;
    padding: 0 20px;
    text-align: center;
    h2, p {
      position: absolute; // This positions the h2 and p elements absolutely within the slide-content
      top: 50%; // This positions the h2 and p elements in the middle of the slide-content
      left: 50%; // This positions the h2 and p elements in the middle of the slide-content
      transform: translate(-50%, -50%); // This ensures the center of the h2 and p elements is at the middle of the slide-content
      z-index: 2; // This ensures the h2 and p elements are displayed on top of the background image
    }
  }
}
.slider_main_img, .slide.active video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slide.active{
  width: 100%;
  position: relative;
  height: 100%;
  object-fit: cover;
}
.prvButton_slider{
  position: absolute;
  top: 44%;
  transform: rotate(180deg);
  left: 1%;
  z-index: 9999 !important;
  width: 45px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  img{
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.fade-enter-active {
    animation: fadeIn 0.5s ease-in-out forwards;
  }

  &.fade-exit-active {
    animation: fadeOut 0.5s ease-in-out forwards;
  }
}
.nextButton_slider{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1%;
  z-index: 9999 !important;
  width: 45px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  img{
    width: 100%;
  }
}
.pausebtn_slider{
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  left: 50%;
  img{
    width: 100%;
  }
}
.call-to-action_slider{
  top: 80%;
  transform: translate(-50%, -50%);
  left: 50%;
  position: absolute;
  font-size: 20px;
  border-radius: 13px;
  background: #c40509;
  background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%);
  border-color: #4444;
  height: 46px;
  font-weight: 700;
}
.call-to-action_slider:hover{
  color: #fff !important;
  border-color: #4444 !important;
  background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%) !important;
}
.call-to-action_slider:focus{
  color: #fff !important;
  border-color: #4444 !important;
  background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%) !important;
}
