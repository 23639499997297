.coachPage {
  padding: 3rem;
  text-align: center;
  background-color: #f3f3f3;
  h1 {
    color: #000000 !important;
  }
  color: #000;
  .twoColumnThreeRowList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; // Adjust this value to your preference
    width: 100%;
    max-width: 7000px;
    > * {
      flex: 1 0 calc(50% - 10px);

      @media (max-width: 600px) {
        flex: 1 0 100%;
      }
    }
    a {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.plan-template {
  background-color: #f3f3f3;
  padding: 3rem;
  .call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    button {
      margin-top: 0;
      margin-bottom: 1rem;
      padding: 1rem 2rem;
    }

    a {
      background-color: #000;
      color: #fff;
      padding: 1rem 2rem;
      border-radius: 5px;
      text-decoration: none;
    }
  }
   p, h1, h2, h3, h4, h5, h6, a, li {
     color: #000 !important;
   }
  h4 {
    font-weight: 900;
  }
  p {
    text-indent: 50px;
  }
  img {
    width: 35px;
  }

  .form {
    margin-top: 5rem;
    .no-image {
      background-color: #000000;
      width: 100%;
      border-radius: 10px;
    }
  }
  .row {
  display: flex;
  justify-content: space-between;
  width: 100%;
    h4 {
      text-align: center;
    }

}
.left {
  text-align: left;
  img {
    rotate: 180deg;
  }
}

.right {
  text-align: right;
}

  @media screen and (max-width: 600px) {
    .plan-template {
      padding: 1rem;
    }
    .form {
      margin-top: 0;
    }
    margin-top: 0;
    padding: 1rem;
    .form_ctm {
      height: auto;
    }
    .row {
      display: block;
    }
    .w20, .w80 {
      width: 100%;
      height: auto;
      /* other styles for smaller screens */
    }
  }
}

.coachPageLink {
  &.divStyle {
    width: 100%;
    height: 320px;
    background-image: var(--background-image);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5));
        z-index: 1;
    }

    h3 {
        z-index: 2;
        position: relative;
        font-weight: 900;
    }
}
}