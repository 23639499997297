.App.CMS.dropemStyles {
  background: #020202;

  a {
    color: #f3f3f3;
    font-size: 1.5rem;

    .logo {
      margin-top: -25px;
    }
  }
}
.dropemStyles {
  .dropem-fitness {
    margin-top: 99px;

    .about-us {
      background-color: #f3f3f3;
      p, h1, h2, h3, h4, h5, h6, a {
        color: #000 !important;
      }
      p {
        text-indent: 50px;
      }

    }
  }
  .call-to-action.ant-btn-primary {
    font-size: 20px;
    border-radius: 13px;
    background: rgb(196,5,9);
    background: linear-gradient(90deg, rgba(196,5,9,1) 0%, rgba(196,46,5,1) 35%, rgba(255,119,0,1) 100%);
    border-color: #4444;
    height: 46px;
    font-weight: 700;
  }

  .dropem-header {
    display: inline-flex;
    justify-content: space-between;
    align-content: space-around;
    align-items: center;
    width: 100%;
    border-bottom: none;
    .desktop {
      width: 100%;
      ul {
        display: inline-flex;
        justify-content: space-evenly;
        align-content: space-around;
        align-items: flex-end;
        width: 100%;
        height: 83px;
        li {
          list-style: none;
          a {
            color: #f3f3f3
          }
        }
      }
      @media (min-width:320px)  {
        display: none;
      }
      @media (min-width:770px) {
        display: block;
      }
      @media (orientation: portrait) {
        display: none;        
      }
    }
    .mobile {
      @media (orientation: portrait) {
        display: block;
        .hamburger {
          right: 1rem;
          background: #020202;
          border-color: #000;
        }
        .menu {
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          height: 100vh;
          background: #333333;
          transition: all 222ms ease-in-out;
          .menu-logo {

            width: 13rem;  
          }

          &.show{
            transition: all 333ms ease-in-out;
            left: 0;
            z-index: 500;
          }
          ul {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: center;
            padding-left: 0px;
            padding-bottom: 2rem;
            height: calc(100vh - 55px);
            width: 100%;
            li {
              list-style: none;
              border-bottom: solid 1px #000;
              padding-top: 0;
              padding-bottom: 0;
              
              a {
                color: #f3f3f3;
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .close {
            position: absolute;
            left: 0;
            bottom: 0px;
            height: 55px;
            width: 100%;
            background: #333;
            border-color: #020202;
          }
        }
      }
      @media (min-width:770px) {
        display: none;
      }
      @media (orientation: portrait) {
        display: block;
      }
    }
  }
    .main-content {
      .ig-placeholder {
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        overflow: auto;
        img {
          height: 255px;
          width: auto;
        }
      }
    }

    .col-2-img {
      width: 100%;
      .col-2 {
        width: 100%;
        text-align: center;
        
        img {
          width: 100%;
          max-width: 500px;
        }
      }
      .call-to-action.ant-btn-primary {
        margin-top: 1rem;
      }
      @media (min-width:750px) {
        .col-2 {
          width: 70%;
        }
      }
    }
}  
